import * as React from "react"
import Seo from "../components/Seo"
import { navigate } from 'gatsby';

const IndexPage = () => {
    React.useEffect(() => {
        navigate('/industrial-design');
    }, [])

    return (
        <><Seo /></>

    )
}

export default IndexPage
